//Copyright by Chaafo pvt ltd
//Offers is used to show all the offers in offers tab of my account section 

import React, {useContext} from 'react';
import {Row,Col} from 'react-bootstrap';
import CouponCard from '../common/CouponCard';
import GlobalContext_ from '../../GlobalContext';


function Offers () {

	//Voucher context is used in a constant variable
	const {voucher} = useContext(GlobalContext_);
    	return (
    		<>
			
    		    <div className='p-4 bg-white shadow-sm'>
	              <Row>
	               <Col md={12}>
	                  <h4 className="font-weight-bold mt-0 mb-3">Offers</h4>
	               </Col>
	               <Col md={6}>
					   
				   {(voucher.voucher?.map(Voucher=>
	               	  <CouponCard 
						 copyBtnText='COPY CODE'
						 couponCode={Voucher.voucher_name}
						 title={Voucher.voucher_title}
						 subTitle={Voucher.voucher_description}
						 discount={Voucher.Discount}
						 noBorder={false}
						  logoImage= 'img/bank/2.png'
	               	  />
						 ))}
	               </Col></Row>
			    </div>
		    </>
    	);
    }

export default Offers;