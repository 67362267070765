//Copyright by Chaafo pvt ltd


import React, { useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PageTitle from './common/PageTitle';
import CouponCard from './common/CouponCard';
import BankOffers from './common/BankOffers';
import GlobalContext_ from '../GlobalContext';

import { Helmet } from 'react-helmet';

function Offers() {

	//Using global context of vouchers
	const { voucher, restaurantinfo } = useContext(GlobalContext_);

	//Function to scroll the tab to top
	const scrollTotop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (
		<>
			<Helmet>
			<meta name="robots" content="index, follow"></meta>
			<link rel="canonical" href="https://kamalchickencorner.com/offers"></link>{/* this needs to be set dynamically */}
				<title>Exclusive Vouchers & Discounts | {restaurantinfo?.merchant_name || 'Kamal Chicken'}</title>
				<meta name="description" content=" Save big on your next chicken meal with Kamal Chicken's exclusive vouchers and discounts. Check out our latest deals and enjoy delicious meals at unbeatable prices." />
				<meta property="og:title" content={`Exclusive Vouchers & Discounts | ${restaurantinfo.merchant_name}`}></meta>
				<meta property="og:description" content=" Save big on your next chicken meal with Kamal Chicken's exclusive vouchers and discounts. Check out our latest deals and enjoy delicious meals at unbeatable prices."></meta>
				<meta property="og:url" content="https://kamalchickencorner.com/offers"></meta> {/* this needs to be set dynamically */}
				<meta property="og:type" content="website"></meta>
				<meta name="keywords" content={'Kamal Chicken vouchers, chicken meal discounts, restaurant deals, food coupons, discount vouchers'} />{/* this needs to be set dynamically */}
			</Helmet>
			<PageTitle
				title="Offers for you"
				subTitle={voucher.offer?.map(offer => <div>{offer}</div>)}
			/>
			<section className="section pt-5 pb-5">
				<Container>
					<Row>
						<Col md={12}>
							<h4 className="font-weight-bold mt-0 mb-3">Available Coupons</h4>
						</Col>
						<Col md={8}>

							{(voucher.voucher?.map(Voucher =>
								<CouponCard
									copyBtnText='COPY CODE'
									couponCode={Voucher.voucher_name}
									title={Voucher.voucher_title}
									subTitle={Voucher.voucher_description}
									discount={Voucher.Discount}
									noBorder={false}
									onClick={scrollTotop}
								/>
							))}
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
}

export default Offers;