//Copyright by Chaafo pvt ltd
//Checkout is designed as whole checkout page

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Container, Form, InputGroup, Button, Tab, Spinner, Nav, Image, OverlayTrigger, Tooltip, Card, Media } from 'react-bootstrap';
import ItemsCarousel from './common/ItemsCarousel';
import ChooseAddressCard from './common/ChooseAddressCard';
import CheckoutItem from './common/CheckoutItem';
import AddAddressModal from './modals/AddAddressModal';
import Icofont from 'react-icofont';
import AddressCard from './common/AddressCard';
import DeleteAddressModal from './modals/DeleteAddressModal';
import { GlobalContext } from './GlobalState';
import GlobalContext_ from '../GlobalContext';
import { RESTAURANT, MERCHANTKEY } from '../App';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useRazorpay from "react-razorpay";
import EditAddress from './modals/EditAddress';
import SetDelivery from './modals/SetDelivery';
import { urls } from './URLs';
import axios from 'axios';
import OrderSummaryCard from './common/OrderSummaryCard';

import { Helmet } from 'react-helmet';

var PAYTM_FB_LOGIN_URL = 'https://chaafo.com/protected/vendor/PaytmKit/pgRedirect2.php?';

function Checkout() {

    // const MK = 'ba7e4279f77945dcd9cf7a4e7f0345e8';
    var T = localStorage.getItem("tokenn");
    var del_id = localStorage.getItem("del_id");
    const { restaurantinfo, MK } = useContext(GlobalContext_);
    const history = useHistory();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSetDelivery, setSetDelivery] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [key1, setKey1] = useState('');
    const [message, setMessage] = useState(null);
    const [service, setService] = useState('');
    const [loading, setLoading] = useState(false);
    const [transactiontype, setTransactiontype] = useState('');
    const [vouchername, setVouchername] = useState('');
    const [points, setPoints] = useState(0);
    const [method, setMethod] = useState(" ");
    const [Mobile, setMobile] = useState('');
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');

    const [show, setShow] = useState(false);

    const showDropdown = (e) => {
        setShow(!show);
    }

    const hideDropdown = e => {
        setShow(false);
    }

    toast.configure();
    const { cart, setCart, setTotal, total, setCount, setEmpty, Pay, setPay, address, setAddress } = useContext(GlobalContext);

    const Razorpay = useRazorpay();
    //Function to fetch payment method lists
    async function PaymentMethod() {
        const response = await fetch(urls.PaymentList + '?merchant_keys=' + MK + '&token=' + T);
        const result = await response.json();
        if (result.code == 1) {
            setMethod(result.details.data);
        }
    }

    //Function to show all saved addresses in address booklist 
    async function Address() {
        const response = await fetch(urls.AddressList + '?&merchant_keys=' + MK + '&token=' + T);
        const data = await response.json();
        if (data.code == 1) {
            setAddress(data.details.data);
        }
    }
    if (address == null) {
        Address();
    }

    //function to show services list i.e. delivery, dine in or pick up
    const ServiceList = () => {
        fetch(urls.ServicesList + '?merchant_keys=' + MK)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    setService(result.details.data);
                    Loadcart();
                    toast.success('Selected', {
                        position: "top-center",
                        autoClose: 400
                    });
                }
            })
    }

    //function to show services list i.e. delivery, dine in or pick up
    const ServiceList1 = () => {
        fetch(urls.ServicesList + '?merchant_keys=' + MK)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    setService(result.details.data);
                    Loadcart();
                }
            }
            )
    }

    //Function to fetch user information
    const GetUserInfo = () => {
        var Token = localStorage.getItem("tokenn");
        fetch(urls.UserInfo + '?merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&token=' + Token + '&lang=en&_=1648619760308')
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    setName(result.details.data.name);
                    setEmail(result.details.data.email_address);
                    setMobile(result.details.data.contact_phone);
                }
            })
    }

    //Function to load all the activities happening on checkout page in cart
    async function Loadcart() {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("device_id", T);
        formdata.append("token", T);
        formdata.append("transaction_type", transactiontype);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.LoadCart + "?merchant_keys=" + MK + "&transaction_type=" + transactiontype + "&token=" + T, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    setCart(result.details.data.item);
                    setTotal(result.details.data.total);
                    setCount(result.details.cart_details);
                    setEmpty('');
                    setPay(result.details);
                }
            })
    }

    useEffect(() => {
        Loadcart();
        PaymentMethod();
    }, [MK]);

    const hideDeleteModal = () => setShowDeleteModal(false);
    const hideAddressModal = () => setShowAddressModal(false);
    const hideEditModal = () => setShowEditModal(false);
    const hideDeliveryModal = () => setSetDelivery(false);
    const getQty = ({ id, quantity }) => {
    }

    //Function to apply voucher in cart
    const VOUCHER = () => {

        fetch(urls.ApplyVoucher + "?merchant_keys=" + MK + "&voucher_name=" + key1 + "&device_id=" + T)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    Loadcart();
                    setMessage(result.msg);
                    setVouchername(result.get.voucher_name);
                    toast.success('Voucher applied!', {
                        position: "top-center",
                        autoClose: 400
                    });
                } else {
                    toast.error(result.msg, {
                        position: "top-center",
                        autoClose: 400
                    });
                }
            }
            )
    }

    //Function to remove voucher from cart
    const REMOVE = () => {
        fetch(urls.RemoveVoucher + '?merchant_keys=' + MK + '&device_id=' + T)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    toast.error("Voucher Removed", {
                        position: "top-center",
                        autoClose: 400
                    });
                    Loadcart();
                }
            }
            )
    }

    // Paytm

    // test axios

    // const PayAxios = () => {
    //     var axios = require('axios');
    //     var FormData = require('form-data');
    //     var data = new FormData();
    //     data.append("token", T);
    //     data.append("delivery_date", Pay.default_delivery_date);
    //     data.append("payment_provider", "paytm");
    //     data.append("transaction_type", Pay.transaction_type);

    //     var config = {
    //         method: 'post',
    //         url: urls.Paynow + "?transaction_type=" + Pay.transaction_type + "&payment_provider=paytm&delivery_date=" + Pay.default_delivery_date + "&merchant_keys=" + MK + "&token=" + T + "&device_id=" + T + "&delivery_time=12:30:00%20PM&device_platform=web",
    //         headers: {
    //             'Cookie': '_lang=en',
    //         },
    //         data: data
    //     };

    //     axios(config)
    //         .then(function (response) {(
    //             console.log(JSON.stringify(r)esponse.data));
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    // const PAYNOW_paytm = () => {
    //     setLoading(true);
    //     var myHeaders = new Headers();
    //     myHeaders.append("Cookie", "_lang=en");

    //     var formdata = new FormData();
    //     formdata.append("token", T);
    //     formdata.append("delivery_date", Pay.default_delivery_date);
    //     formdata.append("payment_provider", "paytm");
    //     formdata.append("transaction_type", Pay.transaction_type);

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: formdata,
    //         redirect: 'follow'

    //     };
    //     fetch(urls.Paynow + "?transaction_type=" + Pay.transaction_type + "&payment_provider=paytm&delivery_date=" + Pay.default_delivery_date + "&merchant_keys=" + MK + "&token=" + T + "&device_id=" + T + "&delivery_time=12:30:00%20PM&device_platform=web", requestOptions)
    //         .then(response => response.json())
    //         .then(result => {

    //             if (result.code === 1) {
    //                 setLoading(false);
    //                 window.location.replace(PAYTM_FB_LOGIN_URL + "ORDER_ID=" + result.details.order_id + "&CUST_ID=" + result.details.client_info.client_id + "&MOBILE_NO=" + result.details.client_info.contact_phone + "&EmailID=" + result.details.client_info.email_address + "&TXN_AMOUNT=" + result.details.total_amount + "&Callbackurl=" + window.location.origin + "/Ls", "_self", '&Restroid=7', 'location=no');
    //                 localStorage.setItem("ORDER_ID", result.details.order_id);
    //             }
    //             else {
    //                 toast.error(result.msg, {
    //                     position: 'top-center',
    //                     autoClose: 500
    //                 });
    //                 setLoading(false);
    //                 history.push('/checkout');
    //             }
    //         }
    //         )
    // }

    // Cash on Delivery/ Pay on Delivery
    const PAYNOW_cod = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");
        var formdata = new FormData();
        formdata.append("token", T);
        formdata.append("delivery_date", Pay.default_delivery_date);
        formdata.append("payment_provider", "cod");
        formdata.append("transaction_type", Pay.transaction_type);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.Paynow + "?transaction_type=" + Pay.transaction_type + "&payment_provider=cod&delivery_date=" + Pay.default_delivery_date + "&order_change=&delivery_asap=false&merchant_keys=" + MK + "&device_id=" + T + "&device_platform=web&token=" + T, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.code == 1) {
                    setLoading(false);
                    localStorage.setItem("ORDER_ID", result.details.order_id);

                    toast.success(result.msg, {
                        position: "top-center",
                        autoClose: 400
                    });
                    setCart(null);
                    setCount(null);
                    Loadcart();

                    history.push('/thanks');
                }
                else {
                    alert(result.msg);
                    setLoading(false);
                    history.push('/checkout');
                }
            }
            )
    }

    //   Razorpay

    const PAYNOW_rzr = useCallback(() => {
        fetch(urls.Paynow + "?transaction_type=" + Pay.transaction_type + "&payment_provider=rzr&delivery_date=" + Pay.default_delivery_date + "&order_change=&delivery_asap=false&merchant_keys=" + MK + "&device_platform=android&token=" + T)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data, 'Data');
                setLoading(false);
            })
            .catch(error => {
                console.log(error, 'Error');
                setLoading(false);
            });
    }, [Pay.default_delivery_date]);

    //Function to apply or redeem loyalty points in cart
    const Points = () => {

        fetch(urls.ApplyPointsUrl + "?merchant_keys=" + MK + "&device_id=" + T + "&token=" + T + "&points=" + points)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    toast.success(result.msg, {
                        position: "top-center",
                        autoClose: 400
                    })
                    Loadcart();
                }
                else {
                    toast.error(result.msg, {
                        position: "top-center",
                        autoClose: 700
                    }
                    )
                }
            }
            )
    }

    //Function to remove points from cart
    const removepoints = () => {
        fetch(urls.RemovePointsUrl + "?merchant_keys=" + MK + "&device_id=" + T + "&token=" + T)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    toast.success('Points removed', {
                        position: "top-center",
                        autoClose: 400
                    })
                    Loadcart();
                }
            }
            )
    }

    return (

        <GlobalContext.Consumer>
            {(state) => (
                <>
                    <Helmet>
                        <meta name="robots" content="index, follow"></meta>
                        <link rel="canonical" href="https://kamalchickencorner.com/checkout"></link>{/* this needs to be set dynamically */}
                        <meta name="description" content="Review your order and proceed to checkout. Make changes to your food cart before placing your order. Quick and easy chicken delivery with Kamal Chicken." />
                        <title>Your Food Cart | {restaurantinfo?.merchant_name || 'Kamal Chicken'}</title>
                        <meta property="og:title" content={`Your Food Cart | ${restaurantinfo.merchant_name}`}></meta>
                        <meta property="og:description" content="Review your order and proceed to checkout. Make changes to your food cart before placing your order. Quick and easy chicken delivery with Kamal Chicken."></meta>
                        <meta property="og:type" content="website"></meta>
                        <meta property="og:url" content="https://kamalchickencorner.com/checkout"></meta> {/* this needs to be set dynamically */}
                        <meta name="keywords" content={'food cart, review order, checkout, online food order, chicken delivery cart'} />{/* this needs to be set dynamically */}
                    </Helmet>

                    <section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
                        <AddAddressModal show={showAddressModal} onHide={hideAddressModal} />
                        <DeleteAddressModal show={showDeleteModal} onHide={hideDeleteModal} />
                        <EditAddress show={showEditModal} onHide={hideEditModal} />
                        <SetDelivery show={showSetDelivery} onHide={hideDeliveryModal} />
                        <Container>
                            <Row>
                                <Col md={8}>
                                    {state.Pay.transaction_type == "Delivery" ?
                                        <h4 className="mb-1">Choose address</h4>
                                        : " "}
                                    <div className="offer-dedicated-body-left">
                                        <div className="pt-2"></div>
                                        <div className="bg-white rounded shadow-sm p-4 mb-4">
                                            <Row>
                                                {state.Pay.transaction_type == "Delivery" ?
                                                    <Col md={6}>
                                                        <ChooseAddressCard
                                                            type="newAddress"
                                                            onAddNewClick={() => setShowAddressModal(true)}
                                                        />
                                                    </Col>
                                                    : ""
                                                }
                                            </Row>
                                            {state.Pay.transaction_type == "Delivery" ?
                                                <Row>
                                                    {
                                                        (state.address?.map(Address =>
                                                            <Col md={6}>
                                                                <AddressCard
                                                                    boxClass="border border-primary shadow"
                                                                    title='Home'
                                                                    id={Address.id}
                                                                    icoIcon='ui-home'
                                                                    iconclassName='icofont-3x'
                                                                    address={Address.address}
                                                                    onEditClick={() => setShowEditModal(true)}
                                                                    onDeleteClick={() => setShowDeleteModal(true)}
                                                                />
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                                : ""
                                            }
                                        </div>
                                        <h4 className="mb-1"> Choose payment method</h4>
                                        <div className="pt-2"></div>
                                        {
                                            state.count == null ? null :
                                                state.count.street || state.Pay.transaction_type == "Pickup" || state.Pay.transaction_type == "Dinein" ?
                                                    <div className="bg-white rounded shadow-sm p-4 osahan-payment">
                                                        <Tab.Container id="left-tabs-example" defaultActiveKey="PAYTM">
                                                            <Row>
                                                                <Col sm={4} className="pr-0">
                                                                    <Nav variant="pills" className="flex-column">
                                                                        {method.paytm ?
                                                                            <Nav.Link eventKey="PAYTM"><img alt='Paytm' src="img\Paytm_Cricle_Logo_PNG_vqitdi.png" className='google' /> Paytm</Nav.Link> : " "}
                                                                        {method.cod ?
                                                                            <Nav.Link eventKey="COD"><img alt='CashOnDelivery' src="img\rupee10.jpg" className='google' />  Pay on Delivery</Nav.Link> : " "}

                                                                        {method.rzr ?
                                                                            <Nav.Link eventKey="RAZORPAY"><img alt='RazorPay' src="img\razorpay_circle.png" className='google' /> Razorpay</Nav.Link> : " "}
                                                                    </Nav>
                                                                </Col>
                                                                <Col sm={8} className="pl-0">
                                                                    <Tab.Content className='h-100'>
                                                                        {method.cod ?
                                                                            <Tab.Pane eventKey="COD">
                                                                                <img alt={'paytm'} src="img\cash-on-delivery.png" ></img>
                                                                                <p>Please keep exact change handy to help us serve you better</p>
                                                                                <hr />
                                                                                <Form>
                                                                                    {loading ? (
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}> <Spinner style={{ display: 'flex', }} animation="border" variant="success" /></div>

                                                                                    ) :
                                                                                        <Link className="btn btn-success btn-block btn-lg" onClick={PAYNOW_cod}>PLACE ORDER</Link>
                                                                                    }

                                                                                </Form>
                                                                            </Tab.Pane> : ""}
                                                                        {method.paytm ?
                                                                            <Tab.Pane eventKey="PAYTM">
                                                                                <img alt='paytm' src="img\paytm.webp"></img>
                                                                                <p>Use Paytm as payment mode</p>
                                                                                <hr />
                                                                                <Form>
                                                                                    {loading ? (
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}> <Spinner style={{ display: 'flex', }} animation="border" variant="success" /></div>

                                                                                    ) :
                                                                                        // <Link className="btn btn-success btn-block btn-lg" onClick={PAYNOW_paytm}>PLACE ORDER</Link>
                                                                                        <a className="btn btn-success btn-block btn-lg" href={`https://bumppy.in/pg/bumppypg3.php?userid=1&amount=${total.total}&load_amount=${total.total}&mobile=${Mobile}&email=${Email}&type=T&cust_name=${Name}&card_no=431581XXXXXXXXX&card_cvv=557&card_exp_month=07&card_exp_year=2030&callback=https://dashboard.bumppy.com&route=PG_USER`}>PLACE ORDER</a>

                                                                                    }
                                                                                </Form>
                                                                            </Tab.Pane> : " "}
                                                                        {method.rzr ?
                                                                            <Tab.Pane eventKey="RAZORPAY">
                                                                                <img alt='Razorpay' src="img\Razorpay_logo.png" className='google'></img>
                                                                                <p>Use Razorpay as payment mode</p>
                                                                                <hr />
                                                                                <Form style={{ width: '100%' }}>
                                                                                    {loading ? (
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}> <Spinner style={{ display: 'flex', }} animation="border" variant="success" /></div>

                                                                                    ) :
                                                                                        // <Link className="btn btn-success btn-block btn-lg" onClick={PAYNOW_rzr}>PLACE ORDER</Link>
                                                                                        <a className="btn btn-success btn-block btn-lg" href={`https://bumppy.in/pg/bumppypg3.php?userid=1&amount=${total.total}&load_amount=${total.total}&mobile=${Mobile}&email=${Email}&type=T&cust_name=${Name}&card_no=431581XXXXXXXXX&card_cvv=557&card_exp_month=07&card_exp_year=2030&callback=https://dashboard.bumppy.com&route=PG_USER`}>PLACE ORDER</a>
                                                                                    }
                                                                                </Form>
                                                                            </Tab.Pane> : ""}
                                                                    </Tab.Content>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Container>
                                                    </div> :
                                                    <div></div>
                                        }
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <OrderSummaryCard
                                        state={state}
                                        key1={key1}
                                        setKey1={setKey1}
                                        getQty={getQty}
                                        VOUCHER={VOUCHER}
                                        REMOVE={REMOVE}
                                        setPoints={setPoints}
                                        Points={Points}
                                        removepoints={removepoints}
                                        service={service}
                                        ServiceList1={ServiceList1}
                                        setTransactiontype={setTransactiontype}
                                        ServiceList={ServiceList}
                                        setShowEditModal={setShowEditModal}
                                        setSetDelivery={setSetDelivery}
                                        pageName='checkout'
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </>
            )}
        </GlobalContext.Consumer>
    );
}

export default Checkout;
