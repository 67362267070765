//Copyright by Chaafo pvt ltd
//CardItem file is designed to show favourites, top offers and spotlight items
//Add button, increase item or decrease item design is made here 

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image, Badge, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from '../../components/GlobalState';
import Detailmodal from '../modals/Detailmodal';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function CardItem(props) {
    const { MK } = useContext(GlobalContext_);

    const {qty, size} = props;
    var T = localStorage.getItem("tokenn");
    const [quantity, setQuantity] = useState(qty);
    const [max] = useState(props.maxValue);
    const [min] = useState(props.minValue || 0);
    const [showDetailmodal, setShowDetailmodal] = useState(false);
    const [price, setPrice] = useState([]);

    const hideDetailmodal = () => setShowDetailmodal(false);

    const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

    const sortCart = (data) => {
        data.sort((a, b) => {
            if (a.sort_id > b.sort_id) {
                return 1;
            }
            else if (b.sort_id > a.sort_id) {
                return -1;
            }
            else {
                return 0;
            }
        }
    )
        return data;
    }

    toast.configure();
    
    useEffect(() => {
        setQuantity(qty || 0);
      }, [qty])

    // funtion to call webloadcart api and renders the cart on time.
    //if user is logged in, that means token is there, therefore if case will be executed. Otherwise, else case will be executed.
    async function Loadcart() {
        var T = localStorage.getItem("tokenn");
    
        if (T) {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");
    
            var formdata = new FormData();
            formdata.append("device_id", T);
            formdata.append("token", T);
    
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
    
            try {
                const response = await fetch(`${urls.LoadCart}?merchant_keys=${MK}`, requestOptions);
                const result = await response.json();
    
                if (result.code === 1) {
                    return result.details.data.item || []; // Return server cart items or empty array
                } else {
                    console.error('Failed to load cart:', result.msg);
                    return []; // Return an empty array if server cart data is not available or fetch failed
                }
            } catch (error) {
                console.error('Error fetching cart data:', error);
                return []; // Return an empty array on fetch error
            }
        } else {
            const raw = localStorage.getItem('cart');
            const cartFetchedFromLocalStorage = JSON.parse(raw);
            return cartFetchedFromLocalStorage || []; // Return local cart data or an empty array if not found
        }
    }
    

    // funtion to load item details
    const addhandler = async () => {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("item_id", props.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        const res = await fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions);
        const raw = await res.json();
        if (raw.details.data.prices.length > 1) {
            localStorage.setItem("id", props.id);
            setShowDetailmodal(true);
        }
        else {
            addtocart();
        }
    }

    //retrieving the total  Qtr. + Half + full 
  const getTotalQuantity = (itemId) => {
    return cart?.filter(item => item.item_id == itemId)
      .reduce((total, item) => total + item.qty, 0);
  };

  const totalQuantity = getTotalQuantity(props.id);

    //Function to add or decrease item
    const addtocart = () => {
        if (quantity >= max) { }
        else {
            setQuantity(quantity + 1);
            props.getValue({ id: props.id, quantity: (quantity + 1) });
        }

        if (T) {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("item_id", props.id);
            formdata.append("price", props.price);
            formdata.append("qty", "1");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(urls.Add_Delete_Item + "?merchant_keys=" + MK + "&device_id=" + T + "&device_platform=web&transaction_type=pickup&lang=en&_=1644817151280&token=" + T, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 1) {
                        // alert(result.msg);
                        Loadcart();
                        toast.success(result.msg, {
                            position: "top-center",
                            autoClose: 400
                        });
                    }
                })
        }


        else {
            const currentItem = cart.filter((item) => {
                return item.item_id === props.id;
            });
            if (currentItem === 'undefined' || currentItem.length == 0) {
                // item is not present in cart yet
                const newItemToAdd = { item_id: props.id, photo: props.image, item_name: props.title, price: props.price, qty: 1, category_id: props.category_id, sort_id: cart.length + 1, size:size } //

                cart.push(newItemToAdd);
                localStorage.setItem('cart', JSON.stringify(cart));
                toast.success('item added', {
                    position: "top-center",
                    autoClose: 400
                });
                Loadcart();

            }
            else {
                // item is present
                const newCart = cart.filter((item) => {
                    return item.item_id !== props.id;
                });
                newCart.push({ ...currentItem[0], qty: currentItem[0].qty + 1 });
                localStorage.setItem('cart', JSON.stringify(newCart));
                toast.success('item added', {
                    position: "top-center",
                    autoClose: 400
                });
                Loadcart();
            }
        }
    }

    // funtion to load item details
    const Itemdetails = () => {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("item_id", props.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions)
            .then(response => response.json())
            .then(result => { setPrice(result.details.data.prices) })
        localStorage.setItem("id", props.id);
    }

    return (

        <div className="list-card bg-white  rounded overflow-hidden position-relative" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
            <Detailmodal show={showDetailmodal} onHide={hideDetailmodal} quantity={quantity}/>
            <div className="list-card-image">
                {props.rating ? (
                    <div className="star position-absolute">
                        <Badge variant="success">
                            <Icofont icon='star' /> {props.rating}
                        </Badge>
                    </div>
                )
                    : ""
                }
                {props.showPromoted ? (
                    <div className="member-plan position-absolute">
                        <Badge variant={props.promotedVariant}>Promoted</Badge>
                    </div>
                )
                    : ""
                }
                <Link onClick={() => setShowDetailmodal(true)}>

                    <Image src={props.image} className={props.imageClass} onClick={Itemdetails} alt={props.imageAlt} />

                </Link>
            </div>
            <div className="p-3 d-flex flex-column" style={{height: '100%'}} >
         <div style={{ height: '6rem' }}>
         <h6 className="text-black" >{props.title}</h6>
         {props.subTitle ? (
              <p className="text-gray">{props.subTitle}</p>
            )
              : ''
            }
         </div>
            <div className='d-flex' style={{ justifyContent: 'space-between'}}>
            <div className="text-gray time mb-0">
              {
              (props.discountprice>0) ? 
              <span className="text-gray time mb-0">
                <del>{props.priceUnit}{props.price} </del>{props.priceUnit}{props.discountprice}
              </span>
               : 
                <p className="text-gray time mb-0">{props.priceUnit}{props.price}</p>
              }
              {(props.isNew) ? (<Badge variant="success" className='ml-1'>NEW</Badge>) : ""}
            </div>

            {(props.price) ?
              quantity===0?
              
              <span className="float-right" >
                <Button variant='outline-secondary' onClick={addhandler} size="sm">ADD</Button>
              </span> 
                :
                <span className="count-number float-right">
                  <Button variant="outline-secondary" onClick={() => setShowDetailmodal(true)} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
                  <input className="count-number-input" type="text" value={totalQuantity} readOnly />
                  <Button variant="outline-secondary" onClick={() => setShowDetailmodal(true)} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
                </span>
              : ''
            }
            </div>
        </div>
        </div>
    );
}

export default CardItem;