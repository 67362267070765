//Copyright by Chaafo pvt ltd

const base = "https://caterr.in/singlemerchant/api/";

const LoginUrl = "flutter_login";
const RegisterUrl = "flutter_customerRegister";
const RegisterMobileUrl = "flutter_customerRegistermobile"
const MobileVerificationUrl = "flutter_verificationmobilenew";

// App.js
const LoadCategoryUrl = "flutter_loadCategory";
const MerchantInfoUrl = "flutter_getmerchantinfo";
const AppSettingsUrl = "flutter_getAppSettings";
const SpotlightUrl = "web_loaditembyspotlight";
const TopPicksUrl = "flutter_loaditembytoppicks";
const RecommendedUrl = "web_loaditembyrecommended";
const AllItemsUrl = "web_loadallitems";
const MerchantPhotoUrl = "flutter_getmerchantphoto";
const TopOffersUrl = "web_loaditembytopoffers";
const VouchersUrl = "flutter_loadpromos";
const BackgroundBannerUrl = "web_merchantbgbanner";

// Detail.js { Except cart api(s)}
const ItemDetailsUrl = "flutter_loaditemdetails";
const SearchItemUrl = "web_searchByItem_new";

// Table Booking
const UserInfoUrl = "flutter_getuserinfo";
const DateListUrl = "flutter_deliverydatelistnew";
const TimeListUrl = "flutter_deliverytimelist";
const BookingTableUrl = "flutter_savebooking";

// Review
const LoadReviewUrl = "flutter_loadreviews";
const CountReviewUrl = "web_reviewcount";
const AddReviewUrl = "flutter_addreview";
const GetReviewUrl = "flutter_getreview";
const UpdateReviewUrl = "flutter_updatereview";
const DeleteReviewUrl = "flutter_deletereview";

// Detail.js end

// Cart Api(s)

const LoadCartUrl = "flutter_webloadcart";
const Add_Delete_ItemUrl = "flutter_webaddtocart";
const RemoveItemUrl = "flutter_webremovecartitem";
const UpdateCartUrl = "flutter_webupdatecart";
const ClearCartUrl = "flutter_clearcart_token";
const ServicesListUrl = "flutter_serviceslist";

// Footer
const NewsLetterUrl = "flutter_newsletter";
const PagesUrl = "flutter_getpages";
const AppUrlUrl = "web_getappurl";
const PagebyIdUrl = "flutter_getpagesbyid";

// Address

const StateListUrl = "web_getstatelist";
const CityListUrl = "web_getcitylist";
const SetAddressUrl = "flutter_websetdeliveryaddress";
const AddressListUrl = "flutter_getaddressbooklist";
const SaveAddressUrl = "flutter_saveaddressbook";
const EditAddressUrl = "flutter_getaddressbook";
const DeleteAddressUrl = "flutter_deleteaddressbook";

// Vouchers

const ApplyVoucherUrl = "flutter_applyvoucher"
const RemoveVoucherUrl = "flutter_removevoucher";

// Points

const ApplyPointsUrl = "flutter_applyredeempoints";
const RemovePointsUrl = "flutter_removepoints";
const PointsSummaryUrl = "flutter_pointsSummary";
const EarnPointsUrl = "flutter_pointsgetearn";
const ExpensePointsUrl = "flutter_pointsexpenses";
const ExpiredPointsUrl = "flutter_pointsexpired";
const MerchantearnPointsUrl = "flutter_pointsearnbymerchant";

// Payment

const PaymentListUrl = "web_loadpaymentlist";
const PaynowUrl = "flutter_webpaynow";
const PaytmConfirmUrl = "flutter_paytmorderconfirmation";
const RzrpayKeyUrl = "flutter_razorpaykey";
const RzrPaySuccessUrl = "flutter_razorpaymentsuccessfull";

// Order

const CancelOrderUrl = "flutter_cancelorder";
const TrackOrderUrl = "flutter_getorderhistory";
const InvoiceUrl = "flutter_getorderdetails";
const ReorderUrl = "flutter_reorder";
const GetOrdersUrl = "flutter_getOrders";

// User

const UserProfileUrl = "flutter_getuserprofile";
const SaveProfileUrl = "flutter_saveprofile";
const ChangePasswordUrl = "flutter_savechangepasswordnew";
const ForgotPasswordUrl = "flutter_requestforgotpassnew1";



const urls = {

  Login: `${base}${LoginUrl}`,
  Register: `${base}${RegisterUrl}`,
  RegisterMobile: `${base}${RegisterMobileUrl}`,
  MobileVerification: `${base}${MobileVerificationUrl}`,
  LoadCategory: `${base}${LoadCategoryUrl}`,
  MerchantInfo: `${base}${MerchantInfoUrl}`,
  AppSettings: `${base}${AppSettingsUrl}`,
  Spotlight: `${base}${SpotlightUrl}`,
  TopPicks: `${base}${TopPicksUrl}`,
  Recommended: `${base}${RecommendedUrl}`,
  AllItems: `${base}${AllItemsUrl}`,
  MerchantPhoto: `${base}${MerchantPhotoUrl}`,
  TopOffers: `${base}${TopOffersUrl}`,
  Vouchers: `${base}${VouchersUrl}`,
  BackgroundBanner: `${base}${BackgroundBannerUrl}`,
  ItemDetails: `${base}${ItemDetailsUrl}`,
  SearchItem: `${base}${SearchItemUrl}`,
  UserInfo: `${base}${UserInfoUrl}`,
  DateList: `${base}${DateListUrl}`,
  TimeList: `${base}${TimeListUrl}`,
  BookingTable: `${base}${BookingTableUrl}`,
  LoadReview: `${base}${LoadReviewUrl}`,
  CountReview: `${base}${CountReviewUrl}`,
  AddReview: `${base}${AddReviewUrl}`,
  GetReview: `${base}${GetReviewUrl}`,
  UpdateReview: `${base}${UpdateReviewUrl}`,
  DeleteReview: `${base}${DeleteReviewUrl}`,
  LoadCart: `${base}${LoadCartUrl}`,
  Add_Delete_Item: `${base}${Add_Delete_ItemUrl}`,
  RemoveItem: `${base}${RemoveItemUrl}`,
  UpdateCart: `${base}${UpdateCartUrl}`,
  ClearCart: `${base}${ClearCartUrl}`,
  ServicesList: `${base}${ServicesListUrl}`,
  NewsLetter: `${base}${NewsLetterUrl}`,
  Pages: `${base}${PagesUrl}`,
  AppUrl: `${base}${AppUrlUrl}`,
  PagebyId: `${base}${PagebyIdUrl}`,
  StateList: `${base}${StateListUrl}`,
  CityList: `${base}${CityListUrl}`,
  SetAddress: `${base}${SetAddressUrl}`,
  AddressList: `${base}${AddressListUrl}`,
  SaveAddress: `${base}${SaveAddressUrl}`,
  EditAddress: `${base}${EditAddressUrl}`,
  DeleteAddress: `${base}${DeleteAddressUrl}`,
  ApplyVoucher: `${base}${ApplyVoucherUrl}`,
  RemoveVoucher: `${base}${RemoveVoucherUrl}`,
  ApplyPoints: `${base}${ApplyPointsUrl}`,
  RemovePoints: `${base}${RemovePointsUrl}`,
  PointsSummary: `${base}${PointsSummaryUrl}`,
  EarnPoints: `${base}${EarnPointsUrl}`,
  ExpensePoints: `${base}${ExpensePointsUrl}`,
  ExpiredPoints: `${base}${ExpiredPointsUrl}`,
  MerchantearnPoints: `${base}${MerchantearnPointsUrl}`,
  PaymentList: `${base}${PaymentListUrl}`,
  Paynow: `${base}${PaynowUrl}`,
  PaytmConfirm: `${base}${PaytmConfirmUrl}`,
  RzrpayKey: `${base}${RzrpayKeyUrl}`,
  RzrPaySuccess: `${base}${RzrPaySuccessUrl}`,
  CancelOrder: `${base}${CancelOrderUrl}`,
  TrackOrder: `${base}${TrackOrderUrl}`,
  Invoice: `${base}${InvoiceUrl}`,
  Reorder: `${base}${ReorderUrl}`,
  GetOrders: `${base}${GetOrdersUrl}`,
  UserProfile: `${base}${UserProfileUrl}`,
  SaveProfile: `${base}${SaveProfileUrl}`,
  ChangePassword: `${base}${ChangePasswordUrl}`,
  ForgotPassword: `${base}${ForgotPasswordUrl}`,

};

export { urls };

