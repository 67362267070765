//Copyright by Chaafo pvt ltd
//Index page will be called at first and the structure of home page is defined here


import TopSearch from './home/TopSearch';
import Detail from './Detail';
import Spotlight from './Spotlight';
import React, { useContext } from 'react';
import GlobalContext_ from '../GlobalContext';
import { Helmet } from 'react-helmet';
import RestaurantInfo from './childComponents/RestaurantInfo';

import { ScrollProvider } from '../ScrollContext';

function Index() {
	const { top_picks, spotlight, category, category_d, restaurantinfo } = useContext(GlobalContext_);

	const myRef = React.useRef(null);

	return (
		<ScrollProvider>
		<Helmet>
			<meta name="robots" content="index, follow"></meta>
			<meta charset="utf-8"></meta>
			<link rel="canonical" href="https://kamalchickencorner.com"></link>{/* this needs to be set dynamically */}
			<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate"></meta>
				<meta name="description" content="Order delicious dishes online from Kamal Chicken. Fast delivery, wide variety, and easy payment options. Satisfy your cravings today!" />
				<title>Delicious Meals Delivered to Your Doorstep | {restaurantinfo?.merchant_name || 'Kamal Chicken'}</title>
				<meta property="og:title" content={`Delicious Meals Delivered to Your Doorstep | ${restaurantinfo.merchant_name}`}></meta>
				<meta property="og:description" content="Order delicious dishes online from Kamal Chicken. Fast delivery, wide variety, and easy payment options. Satisfy your cravings today!"></meta>
				<meta property="og:url" content="https://kamalchickencorner.com"></meta> {/* this needs to be set dynamically */}
				<meta property="og:type" content="website"></meta>
				<meta name="keywords" content={'Kamal Chicken, chicken delivery, order chicken online, local chicken restaurants, fast food delivery, meal delivery service'} />{/* this needs to be set dynamically */}
			</Helmet>
			<TopSearch/>
			<Spotlight />
			<Detail  />
	  </ScrollProvider>
	);
}

export default Index;