//Copyright by Chaafo pvt ltd


import React, { useState, useEffect, createContext } from 'react';
import { Switch, Route, } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Index from './components/Index';
import Vouchers from './components/Vouchers';
import MyAccount from './components/MyAccount';
import TopOffers from './components/TopOffers';
import FooterPagesContent from './components/FooterPagesContent';
import NotFound from './components/NotFound';
import Thanks from './components/Thanks';
import Extra from './components/Extra';
import Login from './components/Login';
import Register from './components/Register';
import OTP from './components/otp';
import otp_Mobile from './components/Otp_Mobile';
import Forgot from './components/Forgot';
import MobileVerification from './components/MobileVerification';
import TrackOrder from './components/TrackOrder';
import Invoice from './components/Invoice';
import Merchants from './components/Merchants';
import Checkout from './components/Checkout';
import Ls from './components/Ls';
import Detail from './components/Detail';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-select2-wrapper/css/select2.css';
import './App.css';
import { urls } from './components/URLs';
import { GlobalProvider } from './components/GlobalState';
import { ToastContainer } from 'react-toastify';
import GlobalContext from './GlobalContext';

var url = window.location.origin;

//creating global context and initializing them to constant variables
const VOUCHER = createContext();
const ITEMS = createContext();
const RECOMMENDED = createContext();
const RESTAURANT = createContext();
const OPEN = createContext();
const CAT_N1 = createContext();
const TOPPICKS = createContext();
const SPOTLIGHT = createContext();
const BANNER = createContext();
const CATEGORY = createContext();
const CATEGORY_D = createContext();
const MERCHANTKEY = createContext();
const TOPOFFERS = createContext();
const GALLERY = createContext();
const LOCATE = createContext();
const BACKGROUND = createContext();

function App(props) {

  const [MerchantKey, setMerchantKey] = useState("");

  // to fetch merchant key from manifest.json file
  const getmk = async () => {
    await fetch(url + "/manifest.json")
      .then(response => response.json())
      .then(result => {
        setMerchantKey(result.MK);
      })
  }
  
  useEffect(() => {
    getmk()
  }, [MK])

  // merchant key :- ef51f5c7281506a95022e5126ff8f80f

  var T = localStorage.getItem("tokenn");
  var MK = MerchantKey;

  var myHeaders = new Headers();
  myHeaders.append("Cookie", "_lang=en");
  var formdata = new FormData();
  formdata.append("device_id", T);
  formdata.append("token", T);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };

  // setting initial states to all the global context variables
  const [voucher, setVoucher] = useState([]);
  const [items, setItems] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [restaurantinfo, setRestaurant] = useState("");
  const [open, setOpen] = useState([]);
  const [cat_n1, setCat] = useState([30]);
  const [top_picks, setToppicks] = useState(null);
  const [spotlight, setSpotlight] = useState(null);
  const [banner, setBanner] = useState([5]);
  const [category, setCategory] = useState('');
  const [category_d, setCategory_d] = useState('');
  const [topoffers, setTopoffers] = useState([]);
  const [gallery, setGallery] = useState([10]);
  const [locate, setLocate] = useState('');
  const [background, setBackground] = useState([]);
  const [pages, setPages] = useState([]);

  const [Mobile, setMobile] = useState('');
  const [zeroItems, setZeroItems] = useState(false);
  const [scrollToCategoryId, setScrollToCategoryId] = useState(null);

  // const [size_words, setsize_words] = useState('');

  // all the api(s) implemented in app.js will be called on page load

  // to fetch current location
  useEffect(() => {
    async function all() {

      // window.scrollTo is used to scroll the tabs at the top
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      //fetching current location
      navigator.geolocation.getCurrentPosition(function (position) {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyB4mMpI8D12QHkyehxumzNsd2SinFY8oZk`)
          .then(response => response.json())
          .then(result => { setLocate(result.results[0].formatted_address) });
      });

      //to fetch background banner of the merchant
        const response1 = await fetch(urls.BackgroundBanner + '?merchant_keys=' + MK); //to fetch background banner of the merchant
        const data1 = await response1.json();
        setBackground(data1.details.bannering);

      //to fetch all the categories from api 
      const response2 = await fetch(urls.LoadCategory + '?merchant_keys=' + MK);
      const data2 = await response2.json();
      setCat(data2.details.data);

      //to fetch merchant information 
      const response3 = await fetch(urls.MerchantInfo + '?merchant_keys=' + MK);
      const data3 = await response3.json();
      setRestaurant(data3.details);
      setOpen(data3.details.opening_hours);

      //
      const response4 = await fetch(urls.AppSettings + '?merchant_keys=' + MK);
      const data4 = await response4.json();
      setBanner(data4.details.singleapp_banner);

      //to fetch spotlight items
      const response5 = await fetch(urls.Spotlight + '?merchant_keys=' + MK);
      const data5 = await response5.json();
      setSpotlight(data5.details.data);
      setCategory(data5.details.cat_id);
      setCategory_d(data5.details.category_description);

      //to fetch top picks items
      const response6 = await fetch(urls.TopPicks + '?merchant_keys=' + MK);
      const data6 = await response6.json();
      setToppicks(data6.details.data);
      setCategory(data6.details.cat_id);

      //to fetch recommended items
      const response7 = await fetch(urls.Recommended + '?merchant_keys=' + MK);
      const data7 = await response7.json();
      setRecommended(data7.details.data);
      setCategory(data7.details.cat_id);

      //to fetch all items
      const response8 = await fetch(urls.AllItems + '?merchant_keys=' + MK);
      const data8 = await response8.json();
      setItems(data8.details);

      //Fetch merchant photo
      const response9 = await fetch(urls.MerchantPhoto + '?&merchant_keys=' + MK + '&token=' + T + '&lang=en&_=1644389135282');
      const data9 = await response9.json();
      setGallery(data9.details.data);

      // fetch items of top offers
      const response10 = await fetch(urls.TopOffers + '?merchant_keys=' + MK);
      const data10 = await response10.json();
      setTopoffers(data10.details.data);
      setCategory(data10.details);

       // fetch pages of footer
       const response11 = await fetch(urls.Pages + "?merchant_keys=" + MK);
       const data11 = await response11.json();
       setPages(data11.details.data);

      }
    all()
  }, [MK]);

  useEffect(() => {

    //fetching voucher name, description, etc.
    async function VOucher() {
      const response = await fetch(urls.Vouchers + '?merchant_keys=' + MK);
      const data1 = await response.json();
      setVoucher(data1.details.data);
    }

    VOucher()
  }, [MK]);

  const contextValues = {
    background,
    voucher,
    items,
    recommended,
    restaurantinfo,
    open,
    cat_n1,
    top_picks,
    spotlight,
    banner,
    category,
    MK,
    category_d,
    topoffers,
    gallery,
    locate,
    zeroItems,
    scrollToCategoryId,
    Mobile,
    pages,
    // size_words,
    // setsize_words,
  }
  

  useEffect(() => {
    if (restaurantinfo.merchant_name) {
      document.title = "Delicious Meals Delivered to Your Doorstep |" + restaurantinfo.merchant_name;
    }
    if (restaurantinfo.merchant_image) {
      const faviconElement = document.getElementById('dynamic-favicon');
      if (faviconElement) {
        faviconElement.href = restaurantinfo.merchant_image;
      } else {
        const newFavicon = document.createElement('link');
        newFavicon.id = 'dynamic-favicon';
        newFavicon.rel = 'shortcut icon';
        newFavicon.href = restaurantinfo.merchant_image;
        document.head.appendChild(newFavicon);
      }
    }
  }, [restaurantinfo.merchant_name, restaurantinfo.merchant_image]);

  return (

    //providing all the data fetched from api's globally 

    <GlobalContext.Provider value={contextValues}>
      <GlobalProvider>
        {
          (props.location.pathname !== '/login' && props.location.pathname !== '/register' && props.location.pathname !== '/forgot' && props.location.pathname !== '/otp' && props.location.pathname !== '/loadingscreen' && props.location.pathname !== '/otp_mobile' && props.location.pathname !== '/MobileVerification' && props.location.pathname !== '/Merchants') ? <Header /> : ''
        }
        <ToastContainer />
        {/* Providing path to all the components  */}
        <Switch>
        {/* {pages?.map(page =>{
           <Route path={`/${page.title.replace(/\s+/g, '')}`} exact component={FooterPagesContent} />
        })} */}
        
        <Route path={`/AboutUs`}  exact component={FooterPagesContent} />
        <Route path={`/PrivacyPolicy`} exact component={FooterPagesContent} />
        <Route path={`/RefundPolicy`} exact component={FooterPagesContent} />
        <Route path={`/DataPolicy`} exact component={FooterPagesContent} />
        <Route path={`/Terms&Conditions`} exact component={FooterPagesContent} />

          <Route path="/Merchants" exact component={Merchants} />
          <Route path="/" exact component={Index} />
          <Route path="/offers" exact component={Vouchers} />
          <Route path="/TopOffers" exact component={TopOffers} />
          <Route path="/myaccount" component={MyAccount} />
          <Route path="/404" exact component={NotFound} />
          <Route path="/extra" exact component={Extra} />
          <Route path="/login" exact component={Login} />
          <Route path="/Register" exact component={Register} />
          <Route path="/track-order" exact component={TrackOrder} />
          <Route path="/invoice" exact component={Invoice} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/thanks" exact component={Thanks} />
          <Route path="/detail" exact component={Detail} />
          <Route path="/OTP" exact component={OTP} />
          <Route path="/otp_mobile" exact component={otp_Mobile} />
          <Route path="/Forgot" exact component={Forgot} />
          <Route path="/MobileVerification" exact component={MobileVerification} />
          <Route path="/Ls" exact component={Ls} />
          <Route exact component={NotFound} />
        </Switch>

        {
          (props.location.pathname !== '/login' && props.location.pathname !== '/register' && props.location.pathname !== '/Forgot' && props.location.pathname !== '/loadingscreen' && props.location.pathname !== '/MobileVerification') ? <Footer /> : ''
        }
      </GlobalProvider>
    </GlobalContext.Provider>
  );
}

export default App;

//exporting all the global states
export { BACKGROUND, VOUCHER, ITEMS, RECOMMENDED, RESTAURANT, OPEN, CAT_N1, TOPPICKS, SPOTLIGHT, BANNER, CATEGORY, MERCHANTKEY, CATEGORY_D, TOPOFFERS, GALLERY, LOCATE };