//Copyright by Chaafo pvt ltd
//MayLikeItem is designed to show searced items and items in carousel


import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types'; 
import {Image,Button} from 'react-bootstrap';
import Icofont from 'react-icofont';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Shimmer from "react-shimmer-effect";
import { MERCHANTKEY } from '../../App';
import Detailmodal from '../modals/Detailmodal';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';


function MayLikeItem(props) {
 
  const {MK} = useContext(GlobalContext_);
  var T = localStorage.getItem("tokenn");
  const [quantity, setQuantity] = useState(props.qty || 0);
  const [max] = useState(props.maxValue || 5);
  const [min] = useState(props.minValue || 0);
  const [showDetailmodal, setShowDetailmodal] = useState(false);
  const getQty = ({ id, quantity }) => {
  } 


  const hideDetailmodal = () => setShowDetailmodal(false);


   // funtion to decrease item quantity
  const DecreaseItem = () => {

    if (quantity <= (min)) {

    } else {
      setQuantity(quantity - 1);
      props.getValue({ id: props.id, quantity: (quantity - 1) });
    }

    if (T) {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "_lang=en");
      var formdata = new FormData();
      formdata.append("item_id", props.id);
      formdata.append("price", props.price);
      formdata.append("row", props.index);
      formdata.append("qty", -1)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(urls.Add_Delete_Item+"?merchant_keys=" + MK + "&device_id=" + T + "&device_platform=android&transaction_type=pickup&lang=en&_=1644817151280&token=" + T, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            Loadcart();
            toast.success('Cart Updated', {
              position: "top-center",
              autoClose: 400
            });
          }
        })
    }

    else {
      const currentItem = cart.filter((item) => {
        return item.item_id === props.id;
      });
      // item is present
      const newCart = cart.filter((item) => {
        return item.item_id !== props.id;
      });
      if (currentItem[0].qty > 1) {
        const itemToAdd = { ...currentItem[0], qty: currentItem[0].qty - 1 };
        newCart.push(itemToAdd);
      }
      localStorage.setItem('cart', JSON.stringify(newCart));
      toast.success('cart updated', {
        position: "top-center",
        autoClose: 400
      });
      Loadcart();

    }
  }

  //Global Contexts used here
  const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);


  const sortCart = (data) =>{
    data.sort((a, b)=>{
      if(a.sort_id>b.sort_id){
      return 1;
      }
      else if(b.sort_id>a.sort_id){
      return -1;
      }
      else{
      return 0;
      }
      
    })
    return data;
    
    }

    toast.configure();


  // funtion to call webloadcart api and renders the cart on time.
  //if user is logged in, that means token is there, therefore if case will be executed. Otherwise, else case will be executed.  
  async function  Loadcart() {
    
    if(T){
       var myHeaders = new Headers();
       myHeaders.append("Cookie", "_lang=en");
       var formdata = new FormData();
       formdata.append("device_id", T);
       formdata.append("token", T)
       var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formdata,
         redirect: 'follow'
       }
       fetch(urls.LoadCart+"?merchant_keys=" + MK, requestOptions)
         .then(response => response.json())
         .then(result => {
           if (result.code === 1) {
             setCart(result.details.data.item);
             setTotal(result.details.data.total);
             setCount(result.details.cart_details);
             setEmpty('');
             setPay(result.details);
           }
           
         })
     
    }
     else {
      const raw = localStorage.getItem('cart');
      const cartFetchedFromLocalStorage = JSON.parse(raw);
      setCart(sortCart(cartFetchedFromLocalStorage));

      const tRaw = localStorage.getItem('total');
      const totalFetchedFromLocalStorage = JSON.parse(tRaw);
      setTotal(totalFetchedFromLocalStorage);

      const cRaw = localStorage.getItem('Count');
      const countFetchedFromLocalStorage = JSON.parse(cRaw);
      setCount(countFetchedFromLocalStorage);

      const eRaw = localStorage.getItem('empty');
      const EmptyFetchedFromLocalStorage = JSON.parse(eRaw);
      setEmpty(EmptyFetchedFromLocalStorage);

      const pRaw = localStorage.getItem('pay');
      const payFetchedFromLocalStorage = JSON.parse(pRaw);
      setPay(payFetchedFromLocalStorage);
     }
  }


   //Function to add or decrease item
  const addtocart = () => {
    if (quantity >= max) { }
    else {
      setQuantity(quantity + 1);
      props.getValue({ id: props.id, quantity: (quantity + 1) });
    }

    if(T){
       var myHeaders = new Headers();
       myHeaders.append("Cookie", "_lang=en");

       var formdata = new FormData();
       formdata.append("item_id", props.id);
       formdata.append("price", props.price);
       formdata.append("qty", "1")
       var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formdata,
         redirect: 'follow'
       };
       fetch(urls.Add_Delete_Item+"?merchant_keys=" + MK + "&device_id="+T+"&device_platform=web&lang=en&_=1644817151280&token=" + T, requestOptions)
         .then(response => response.json())
         .then(result => {
           if (result.code === 1) {
             toast.success(result.msg, {
               position: "top-center",
               autoClose: 400
             });
             Loadcart()
           }
          })
          }
         
     
     else {
      const currentItem = cart.filter((item) => {
        return item.item_id === props.id;
      });
      if (currentItem === 'undefined' || currentItem.length == 0) {
        // item is not present in cart yet
        const newItemToAdd = { item_id:props.id,photo:props.image, item_name: props.title,price:props.price, qty:1, category_id: props.category_id, sort_id: cart.length+1 } //
        
        cart.push(newItemToAdd);
        localStorage.setItem('cart', JSON.stringify(cart));

        toast.success('item added', {
          position: "top-center",
          autoClose: 400
        });
        Loadcart();

      } else {
        // item is present
        const newCart = cart.filter((item)=>{
          return item.item_id!==props.id;
        });
        newCart.push({...currentItem[0],qty:currentItem[0].qty+1});
        localStorage.setItem('cart', JSON.stringify(newCart));
        toast.success('item added', {
          position: "top-center",
          autoClose: 400
        });
        Loadcart();
      }
    
}
  }


   // funtion to load item details
  const addhandler = async() => {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_lang=en"); 
  
    var formdata = new FormData();
    formdata.append("item_id", props.id);
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    const res = await fetch(urls.ItemDetails+"?merchant_keys=" + MK, requestOptions);
    const raw =await res.json();
    if(raw.details.data.prices.length>1){
      localStorage.setItem("id", props.id);
      setShowDetailmodal(true);
    }
    else{
      addtocart();
    }
  
    }




      return (
        
        <div className={"position-relative " + props.boxClass}>
          <Detailmodal show={showDetailmodal} onHide={hideDetailmodal} />
                <Image src={props.image} className={props.imageClass} alt={props.imageAlt} />
                {props.title?
                 <h6>{props.title}</h6>
                 :""
              }
              <div className="p-2 position-relative">
              {props.price?
                <small className='float-left'>₹ {props.price}
                 </small>
              :""
              }
              {quantity === 0?
              
                <span className="float-right">
                  <Button variant='outline-secondary' onClick={addhandler} size="sm">ADD</Button>
                </span>
                :
                <span className="count-number float-right">
                  <Button variant="outline-secondary" onClick={DecreaseItem} className="btn-sm left dec btn"> <Icofont icon="minus" /> </Button>
                  <input className="count-number-input" type="text" value={quantity} readOnly />
                  <Button variant="outline-secondary" onClick={addtocart} className="btn-sm right inc btn"> <Icofont icon="icofont-plus" /> </Button>
                </span>
              } 
              
              </div>
            </div>
           
    );
    
  }

MayLikeItem.propTypes = {
  onAdd: PropTypes.func,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  boxClass: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired,
  category_id: PropTypes.number.isRequired
};
MayLikeItem.defaultProps = {
    imageAlt:'',
    image:'',
    imageClass:'',
    boxClass:'mall-category-item',
    title:'',
    price:'',
}

export default MayLikeItem;
