//Copyright by Chaafo pvt ltd
//List file is to show the items of top offers

import React, { useState, useEffect, useContext } from 'react';
import GlobalContext_ from '../GlobalContext';
import { Row, Col, Container, } from 'react-bootstrap';
import PageTitle from './common/PageTitle';
import CardItem from './common/CardItem';
import { GlobalContext } from './GlobalState';

import { Helmet } from 'react-helmet';

function TopOffers() {

	//Using contexts of merchant key, top offers and category that are defined globally
	const { MK, topoffers, category, restaurantinfo } = useContext(GlobalContext_);

	return (
		<GlobalContext.Consumer>
			{
				(state) => (
					<>
						<Helmet>
							<meta name="robots" content="index, follow"></meta>
							<link rel="canonical" href="https://kamalchickencorner.com/TopOffers"></link>{/* this needs to be set dynamically */}
							<meta name="description" content="Discover the best offers and deals at Kamal Chicken. From limited-time promotions to daily specials, enjoy great savings on your favorite chicken dishes." />
							<title>Top Offers & Deals | {restaurantinfo?.merchant_name || 'Kamal Chicken'}</title>
							<meta property="og:title" content={`Top Offers & Deals | ${restaurantinfo.merchant_name}`}></meta>
							<meta property="og:description" content="Discover the best offers and deals at Kamal Chicken. From limited-time promotions to daily specials, enjoy great savings on your favorite chicken dishes."></meta>
							<meta property="og:type" content="website"></meta>
							<meta property="og:url" content="https://kamalchickencorner.com/TopOffers"></meta> {/* this needs to be set dynamically */}
							<meta name="keywords" content={'top chicken offers, best non veg restaurant , discount on meals, food discounts, special offers'} />{/* this needs to be set dynamically */}
						</Helmet>
						<PageTitle
							title="Top Offers For You"
							subTitle="Best deals at your doorstep"
						/>
						<section className="section pt-5 pb-5 products-listing">
							<Container>
								<Row className="d-none-m">

								</Row>
								<Row>

									<Col md={12}>
										<Row>
											{(topoffers?.map(offers =>
												<Col md={3} sm={6} className="mb-4 pb-2">

													<CardItem
														qty={state?.cart?.find(cartItem => cartItem.item_id === offers.item_id)?.qty ?? 0}

														title={offers.item_name}
														subTitle='Indian • American • Italian'
														imageAlt={offers.photo}
														image={offers.photo}
														id={offers.item_id}
														imageClass='list item-img'
														linkUrl='detail'
														// offerText='65% off | Use Coupon OSAHAN50'
														// time='15–25 min'
														price={offers.prices.price}
														discountprice={offers.prices.discount_price}
														size={offers.prices.size}
														priceUnit='₹ '
														//showPromoted={true}
														category_id={category.cat_id}
													// promotedVariant='dark'
													// favIcoIconColor='text-danger'
													// rating='3.1 (300+)'
													/>
												</Col>
											))}
											<Col md={12} className="text-center load-more">
											</Col>
										</Row>
									</Col>
								</Row>
							</Container>

						</section>
					</>

				)
			}
		</GlobalContext.Consumer>
	)
}

export default TopOffers;